<template>
    <div>
        <BackOnTop url="/member" />
        <h2>寶貝管理</h2>
        <div class="baby_wrappper">
            <div v-if="babyList.length && !$store.state.isLoading">
                <PetItem
                    v-for="(el, idx) in babyList"
                    :key="idx"
                    :data="el"
                    :index="idx"
                    @editHandler="editHandler(el.id, idx)"
                ></PetItem>
                <FancyButton
                    v-if="babyList.length && !$store.state.isLoading"
                    width="150"
                    height="45"
                    color="primary"
                    label="立即新增"
                    to="/register/pet"
                />
            </div>
            <div v-else>
                <BabyPhoto
                    v-if="!$store.state.isLoading && !isLoading"
                    :imgSrc="emptySrc"
                    :title="emptytitle"
                />
                <FancyButton
                    v-if="!$store.state.isLoading && !isLoading"
                    width="150"
                    height="45"
                    color="primary"
                    label="立即新增"
                    to="/register/pet"
                />
            </div>
        </div>
        <Panel
            :open="openEditPanel"
            :editTitle="'編輯寶貝'"
            @closePn="closePn"
            class="pn"
        >
            <ul class="func">
                <li
                    v-for="(el, idx) in editFuncList"
                    :class="{ active: currentFuc === el.type }"
                    :key="idx"
                    @click="funcHandler(el.type)"
                >
                    <p v-if="el.type === 'editInfo'">{{ el.text }}</p>
                    <div v-else>
                        <label for="file">{{ el.text }}</label>
                        <input
                            type="file"
                            id="file"
                            name="file"
                            ref="file"
                            accept="image/*"
                            @change="handleFileUpload()"
                            hidden
                        />
                    </div>
                </li>
            </ul>
        </Panel>
    </div>
</template>

<script>
import BackOnTop from '@/components/BackOnTop.vue';
import PetItem from '@/components/PetItem.vue';
import BabyPhoto from '@/components/BabyPhoto.vue';
import FancyButton from '@/components/FancyButton.vue';
import Panel from '@/components/Panel.vue';
import {
    KgGetPetType,
    KgGetPetBreed,
    KgGetPetList,
    queryPet,
    insertPet,
    isPetExist,
    uploadPetImage,
    getPetImage,
    syncPetData,
} from '@/apis/api.js';

export default {
    name: 'PetIndex',
    components: { BackOnTop, PetItem, BabyPhoto, FancyButton, Panel },
    data: () => ({
        babyList: [],
        emptySrc: require('@/assets/images/imgBabyCover.jpg'),
        emptytitle: '您目前尚未新增寶貝資料喔！',
        editId: '',
        editIdx: '',
        openEditPanel: false,
        editFuncList: [
            { text: '更換照片', type: 'changePhoto' },
            { text: '編輯寶貝資料', type: 'editInfo' },
        ],
        currentFuc: '',
        type_dict: {},
        breed_dict: {},
        gender_dict: {
            '01': '公',
            '02': '母',
            '03': '其他',
        },
        file: '',
        isLoading: true,
    }),
    beforeCreate() {
        this.$store.state.isLoading = true;
    },
    async created() {
        const parent = this;
        this.$store.state.isLoading = true;

        await this.generateBreedList();
        this.$store.state.isLoading = true;
        await syncPetData(
            localStorage.getItem('phone'),
            localStorage.getItem('password')
        );
        this.$store.state.isLoading = true;
        const petTypeRsp = await KgGetPetType(localStorage.getItem('phone'));
        this.$store.state.isLoading = true;
        for (let j in petTypeRsp) {
            parent.type_dict[petTypeRsp[j].PET_Type] =
                petTypeRsp[j].PET_TypeName;
        }
        this.$store.state.isLoading = true;
        let rsp = await KgGetPetList(
            localStorage.getItem('phone'),
            localStorage.getItem('member_no')
        );
        this.$store.state.isLoading = true;
        parent.babyList = [];

        rsp = rsp.reverse();
        console.log(rsp);
        
        for await (let petObj of rsp) {
            parent.$store.state.isLoading = true;
            var birth = '';
            if (petObj.PET_Birth)
                birth =
                    petObj.PET_Birth.slice(0, 4) +
                    '/' +
                    petObj.PET_Birth.slice(4, 6) +
                    '/' +
                    petObj.PET_Birth.slice(6, 8);
            // parent.babyList.push();
            let babyObj = {
                id: petObj.PET_SeqNo,
                alias: petObj.PET_Name,
                gender: parent.gender_dict[petObj.PET_Sex],
                birthday: birth,
                breed: petObj.PET_Breed
                    ? parent.breed_dict[
                          petObj.PET_Type + '-' + petObj.PET_Breed
                      ]
                    : parent.type_dict[petObj.PET_Type],
                isNeutered: petObj.PET_Fix == '0' ? false : true,
                imgSrc: require('@/assets/images/imgCard' +
                    petObj.PET_Type +
                    '.jpg'),
                show: false,
            };
            const petRsp = await queryPet(
                localStorage.getItem('phone'),
                localStorage.getItem('password'),
                petObj.PET_SeqNo
            );
            if (!petRsp) {
                const insertPetRsp = await insertPet(
                    localStorage.getItem('phone'),
                    localStorage.getItem('password'),
                    petObj.PET_SeqNo,
                    petObj.PET_Name
                );
                if (insertPetRsp) {
                    parent.$store.state.isLoading = true;
                    babyObj.show = true;
                    parent.babyList.push(babyObj);
                    if (rsp.indexOf(petObj) == rsp.length - 1) {
                        parent.$store.state.isLoading = true;
                        setTimeout(function () {
                            parent.$store.state.isLoading = false;
                            parent.isLoading = false;
                        }, 1000);
                    }
                } else {
                    parent.$store.state.isLoading = true;
                    if (rsp.indexOf(petObj) == rsp.length - 1) {
                        setTimeout(function () {
                            parent.$store.state.isLoading = false;
                            parent.isLoading = false;
                        }, 1000);
                    }
                }
            } else {
                const petExistRsp = await isPetExist(
                    localStorage.getItem('phone'),
                    localStorage.getItem('password'),
                    petObj.PET_SeqNo
                );
                if (petExistRsp) {
                    getPetImage(
                        localStorage.getItem('phone'),
                        localStorage.getItem('password'),
                        petObj.PET_SeqNo
                    ).then(function (image) {
                        parent.$store.state.isLoading = true;
                        babyObj.show = true;
                        babyObj.imgSrc = image
                            ? image
                            : require('@/assets/images/imgCard' +
                                  petObj.PET_Type +
                                  '.jpg');
                        parent.babyList.push(babyObj);
                        if (rsp.indexOf(petObj) == rsp.length - 1) {
                            setTimeout(function () {
                                parent.$store.state.isLoading = false;
                                parent.isLoading = false;
                            }, 1000);
                        }
                    });
                } else {
                    parent.$store.state.isLoading = true;
                    if (rsp.indexOf(petObj) == rsp.length - 1) {
                        setTimeout(function () {
                            parent.$store.state.isLoading = false;
                            parent.isLoading = false;
                        }, 1000);
                    }
                }
            }
        }

        if(rsp.length==0)
        {
            setTimeout(()=>{
                parent.$store.state.isLoading = false;
                parent.isLoading = false;
            },1000)

        }
        console.log('finish');
    },
    methods: {
        generateBreedList() {
            const parent = this;
            return new Promise(function (resolve) {
                KgGetPetBreed(localStorage.getItem('phone')).then(function (
                    rsp
                ) {
                    for (let breedInfo of rsp) {
                        parent.breed_dict[
                            breedInfo.PET_Type + '-' + breedInfo.PET_Breed
                        ] = breedInfo.PET_BreedName;
                    }
                    resolve();
                });
            });
        },
        tabHandler(value) {
            this.currentTab = value;
        },
        editHandler(id, idx) {
            this.editId = id;
            this.editIdx = idx;
            this.openEditPanel = true;
        },
        closePn() {
            this.editId = '';
            this.editIdx = '';
            this.openEditPanel = false;
        },
        funcHandler(type) {
            this.currentFuc = type;
            var parent = this;
            if (type === 'editInfo') {
                this.$router.push({
                    path: '/pet/edit?id=' + parent.editId,
                });
            }
        },
        handleFileUpload() {
            this.$store.state.isLoading = true;
            var parent = this;
            this.file = this.$refs.file[0].files[0];
            var href = URL.createObjectURL(this.file);
            uploadPetImage(
                localStorage.getItem('phone'),
                this.editId,
                this.file
            ).then(function (rsp) {
                parent.babyList[parent.editIdx].imgSrc = href;
                console.log('uploadPetImage', rsp);
                parent.openEditPanel = false;
                parent.$store.state.isLoading = false;
                parent.isLoading = false;
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.baby_wrappper {
    padding: 20px 16px;
}

h2 {
    color: $color_main_first;
    font-size: 26px;
    font-weight: bold;
    padding: 12px 16px;
    line-height: 1;
    text-align: left;
}

.list {
    margin-top: 12px;
    padding: 0 16px 70px;
}

a {
    font-size: 16px !important;
    font-weight: 500;
    border-radius: 25px;
    position: fixed;
    left: 50%;
    bottom: 36px;
    transform: translate(-50%, 0);
}

.func {
    list-style: none;

    li {
        margin: 8px 0;
        line-height: 40px;
        text-align: center;
        width: 100%;

        &.active {
            background: rgba(249, 98, 48, 0.15);
        }
    }
}
</style>
